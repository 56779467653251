import React, { useRef, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { navigate } from 'gatsby'
import algoliasearch from 'algoliasearch/lite'
import qs from 'qs'
// Hooks
import { useClickOutside } from 'hooks/use-click-outside'
// Components
import { InstantSearch } from 'react-instantsearch-dom'
import SearchForm from './SearchForm'
import SearchResult from './SearchResult'
// Styles
import * as styles from './index.module.css'

type IndexProps = {
    name: string
    title: string
}

type SearchProps = {
    show: boolean
    onClose: () => void
    indices: IndexProps[]
}

// const __DEBOUNCE_TIME__ = 400

// const urlToSearchState = (location: Location) => {
//     const { query = '' } = qs.parse(location.search.slice(1))
//     return {
//         query: decodeURIComponent(query as string),
//     }
// }
// const searchStateToUrl = (searchState) =>
//     searchState ? createURL(searchState) : ''
// const createURL = (state) => {
//     if (!state.query) return '?'
//     return `?${qs.stringify({ query: state.query })}`
// }

const Search: React.FC<SearchProps> = ({ show, indices, onClose }) => {
    // const [searchState, setSearchState] = useState(
    //     typeof window !== 'undefined' && urlToSearchState(location)
    // )
    // const [debouncedSetState, setDebouncedSetState] = useState<null | any>(null)

    const searchBoxRef = useRef(null)
    useClickOutside(searchBoxRef, onClose)

    const searchClient = useMemo(
        () =>
            algoliasearch(
                process.env.GATSBY_ALGOLIA_APP_ID!,
                process.env.GATSBY_ALGOLIA_SEARCH_KEY!
            ),
        []
    )

    if (!show) return <></>

    // const onSearchStateChange = (updatedSearchState) => {
    //     clearTimeout(debouncedSetState!)

    //     setQuery(query)
    //     setDebouncedSetState(
    //         setTimeout(() => {
    //             navigate(searchStateToUrl(updatedSearchState))
    //         }, __DEBOUNCE_TIME__)
    //     )

    //     setSearchState(updatedSearchState)
    // }

    const searchElement = (
        <>
            <div className={styles.search_modal}>
                <InstantSearch
                    searchClient={searchClient}
                    indexName={indices[0].name}
                    // onSearchStateChange={onSearchStateChange}
                    // searchState={searchState}
                    // createURL={createURL}
                >
                    <div ref={searchBoxRef} className={styles.search_box}>
                        <SearchForm
                            className={styles.search_bar}
                            onClose={onClose}
                        />
                        <SearchResult indices={indices} />
                    </div>
                </InstantSearch>
            </div>
        </>
    )

    const portalTo =
        typeof document !== `undefined`
            ? document.getElementById('search-hook')
            : null

    if (show && portalTo) {
        return createPortal(searchElement, portalTo)
    }
}

export default Search
