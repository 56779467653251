import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connectHits, Highlight } from 'react-instantsearch-dom'
// Models
import { Hit } from 'react-instantsearch-core'
import { IArticleHit } from 'models/Article'

type ArticleHitsProps = {
    hits: Hit<IArticleHit>[]
}

const ArticleHits: React.FC<ArticleHitsProps> = ({ hits }) => {
    const mainHits = hits.slice(0, 5)
    return (
        <div>
            {mainHits.map((hit) => {
                const { slug, objectID } = hit
                return (
                    <Link to={`/articles/${slug}`} key={objectID}>
                        <FontAwesomeIcon icon={['far', 'newspaper']} />
                        <Highlight
                            tagName="strong"
                            nonHighlightedTagName="span"
                            hit={hit}
                            attribute="title"
                        />
                    </Link>
                )
            })}
        </div>
    )
}

export default connectHits(ArticleHits)
