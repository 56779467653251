import React from 'react'
import {
    connectStateResults,
    StateResultsProvided,
} from 'react-instantsearch-core'

const IndexResults: React.FC<StateResultsProvided> = ({
    searchResults,
    children,
}) => {
    if (searchResults && searchResults.nbHits !== 0) return <>{children}</>
    return <></>
}

export default connectStateResults(IndexResults)
