import React from 'react'
import { createPortal } from 'react-dom'
// Styles
import * as styles from './index.module.css'

type BackdropProps = {
    show: boolean
    onClose: () => void
}

const Backdrop: React.FC<BackdropProps> = ({ show, onClose }) => {
    const backdropElement = (
        <section className={styles.backdrop} onClick={onClose}></section>
    )
    const portalTo =
        typeof document !== `undefined`
            ? document.getElementById('backdrop-hook')
            : null

    if (show && portalTo) {
        return createPortal(backdropElement, portalTo)
    }

    return <></>
}

export default Backdrop
