import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connectHits, Highlight } from 'react-instantsearch-dom'
// Models
import { Hit } from 'react-instantsearch-core'
import { IDiskHit } from 'models/Disk'

type DiskHitsProps = {
    hits: Hit<IDiskHit>[]
}

const DiskHits: React.FC<DiskHitsProps> = ({ hits }) => {
    const mainHits = hits.slice(0, 5)
    return (
        <div>
            {mainHits.map((hit) => {
                const { slug, objectID } = hit
                return (
                    <Link to={`/discography/${slug}`} key={objectID}>
                        <FontAwesomeIcon icon={['fas', 'compact-disc']} />
                        <Highlight
                            tagName="strong"
                            nonHighlightedTagName="span"
                            hit={hit}
                            attribute="name"
                        />
                    </Link>
                )
            })}
        </div>
    )
}

export default connectHits(DiskHits)
