import React from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'
import 'util/fontawesome'
config.autoAddCss = false
// Components
import SEO from 'components/SEO'
import Footer from './Footer'
import Header from './Header'
import Main from './Main'
// Models
import { IOptionalMetaProps } from 'models/SEO'

type LayoutProps = {
    seo: IOptionalMetaProps
}

const Layout: React.FC<LayoutProps> = ({ children, seo }) => {
    return (
        <div>
            <SEO seo={seo} />
            <Header />
            <Main>{children}</Main>
            <Footer />
        </div>
    )
}

export default Layout
