import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
// Models
import { IPageBase } from 'models/Page'
// Styles
import * as styles from './Footer.module.css'

const Footer: React.FC = () => {
    const { pages } = useStaticQuery(query) as { pages: { nodes: IPageBase[] } }
    const footerPages = [
        { title: 'Artistas', slug: 'artists' },
        { title: 'Usuarios', slug: 'users' },
    ].concat(pages.nodes)
    return (
        <footer className={styles.footer}>
            <nav>
                <span className={styles.copyright}>
                    KenshiYonezu.net &copy; {new Date().getFullYear()}
                </span>
                <p className={styles.disclaimer}>
                    Esta página no está asociada a Kenshi Yonezu ni a sus
                    representantes.
                </p>
                <p className={styles.pages}>
                    {footerPages.map((page, index) => (
                        <React.Fragment key={page.slug}>
                            <Link to={`/${page.slug}`}>{page.title}</Link>
                            {index === footerPages.length - 1 ? '' : ' - '}
                        </React.Fragment>
                    ))}
                </p>
            </nav>
        </footer>
    )
}

export default Footer

const query = graphql`
    query {
        pages: allContentfulPages {
            nodes {
                title
                slug
            }
        }
    }
`
