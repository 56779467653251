import React from 'react'
import { Link } from 'gatsby'
// Styles
import * as styles from './index.module.css'

const Brand: React.FC = () => {
    const randomLogo = new Date().getDate() % 20

    return (
        <div className={styles.brand}>
            <Link to="/">
                <img
                    className={styles.logo}
                    src={`/images/logo/Monsters${randomLogo}.png`}
                    alt="Logo"
                />
            </Link>

            <Link to="/">
                <h1 className={styles.site_name}>Kenshi Yonezu</h1>
            </Link>
        </div>
    )
}

export default Brand
