import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connectHits, Highlight } from 'react-instantsearch-dom'
// Models
import { Hit } from 'react-instantsearch-core'
import { ISongHit } from 'models/Song'

type SongHitsProps = {
    hits: Hit<ISongHit>[]
}

const SongHits: React.FC<SongHitsProps> = ({ hits }) => {
    const mainHits = hits.slice(0, 5)
    return (
        <div>
            {mainHits.map((hit) => {
                const { slug, objectID } = hit
                return (
                    <Link to={`/songs/${slug}`} key={objectID}>
                        <FontAwesomeIcon icon={['fas', 'music']} />
                        <Highlight
                            tagName="strong"
                            nonHighlightedTagName="span"
                            hit={hit}
                            attribute="name"
                        />
                    </Link>
                )
            })}
        </div>
    )
}

export default connectHits(SongHits)
