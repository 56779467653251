import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchBoxProvided } from 'react-instantsearch-core'

interface SearchFormProps extends SearchBoxProvided {
    className?: string
    onClose: () => void
}

const SearchForm: React.FC<SearchFormProps> = ({
    refine,
    currentRefinement,
    className,
    onClose,
}) => {
    const searchClassName = className || ''
    return (
        <form
            className={searchClassName}
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <FontAwesomeIcon icon={['fas', 'search']} />
            <input
                className="SearchInput"
                autoFocus
                type="text"
                placeholder="Buscar"
                aria-label="Buscar"
                onChange={(e) => refine(e.target.value)}
                value={currentRefinement}
            />
            <FontAwesomeIcon
                onClick={onClose}
                data-close
                icon={['fas', 'times']}
            />
        </form>
    )
}

export default connectSearchBox(SearchForm)
