import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Components
import Brand from 'components/UIElements/Brand'
import Menu from './Menu'
import SearchBar from './SearchBar'
// Styles
import * as styles from './Header.module.css'

const Header: React.FC = () => {
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState<boolean | undefined>(() => {
        if (typeof document !== `undefined`)
            return window.innerWidth < 992 ? true : false
        return undefined
    })

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992 ? true : false)
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const openMobileMenu = () => {
        setShowMobileMenu(true)
    }
    const closeMobileMenu = () => {
        setShowMobileMenu(false)
    }

    return (
        <header className={styles.header}>
            <nav>
                {/* {isMobile && ( */}
                <div className={styles.menu_toggle}>
                    <FontAwesomeIcon
                        onClick={openMobileMenu}
                        icon={['fas', 'bars']}
                    />
                </div>
                {/* )} */}

                <Brand />
                <SearchBar />
                <Menu
                    mobile={isMobile}
                    show={showMobileMenu}
                    closeMenu={closeMobileMenu}
                />
            </nav>
        </header>
    )
}

export default Header
