import { library } from '@fortawesome/fontawesome-svg-core'

/* FAB */
import {
    faTwitter,
    faInstagram,
    faLine,
    faFacebook,
    faSpotify,
    faYoutube,
    faDeezer,
    faAmazon,
    faGoogle,
    faGithub,
    faPatreon,
    faPinterest,
    faWhatsapp,
    faTelegram,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons'

/* FAS */
import {
    faChevronLeft,
    faChevronRight,
    faLanguage,
    faGlobe,
    faStar,
    faInfoCircle,
    faCheckCircle,
    faBars,
    faCoffee,
    faSearch,
    faGuitar,
    faUser,
    faUsers,
    faMusic,
    faCompactDisc,
    faCommentAlt,
    faBookOpen,
    faLink,
    faHashtag,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
/* FAR */
import {
    faClock,
    faCalendarAlt,
    faNewspaper,
    faClosedCaptioning,
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faAmazon,
    faBars,
    faBookOpen,
    faCalendarAlt,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faClock,
    faClosedCaptioning,
    faCoffee,
    faCommentAlt,
    faCompactDisc,
    faDeezer,
    faFacebook,
    faGithub,
    faGlobe,
    faGoogle,
    faGuitar,
    faHashtag,
    faInfoCircle,
    faInstagram,
    faLanguage,
    faLine,
    faLink,
    faMusic,
    faNewspaper,
    faPatreon,
    faPinterest,
    faSearch,
    faSpotify,
    faStar,
    faTelegram,
    faTiktok,
    faTimes,
    faTwitter,
    faUser,
    faUsers,
    faWhatsapp,
    faYoutube
)
