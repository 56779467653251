import React from 'react'
import {
    connectStateResults,
    Index,
    StateResultsProvided,
} from 'react-instantsearch-core'
// Components
import IndexResults from './IndexResults'
import DiskHits from './Hits/DiskHits'
import SongHits from './Hits/SongHits'
import ArticleHits from './Hits/ArticleHits'
import InterviewHits from './Hits/InterviewHits'
import ArtistHits from './Hits/ArtistHits'
import UserHits from './Hits/UserHits'
import Empty from './Hits/Empty'
import NoHits from './Hits/NoHits'
// Styles
import * as styles from './SearchResult.module.css'

type IndexProps = {
    name: string
    title: string
}

interface SearchResultsProps extends StateResultsProvided {
    indices: IndexProps[]
}

const SearchResults: React.FC<SearchResultsProps> = ({
    allSearchResults,
    searchState,
    indices,
}) => {
    const hasResults =
        allSearchResults &&
        Object.values(allSearchResults).some((results) => results.nbHits > 0)
    if (!searchState?.query) return <Empty />
    if (!hasResults) {
        return (
            <>
                <NoHits />
                {indices.map((indice) => (
                    <Index key={indice.name} indexName={indice.name} />
                ))}
            </>
        )
    }

    return (
        <div className={styles.search_results}>
            {indices.map((indice) => (
                <Index key={indice.name} indexName={indice.name}>
                    <IndexResults>
                        <section data-section={indice.name}>
                            <h2>{indice.title}</h2>
                            {indice.name === 'Songs' && <SongHits />}
                            {indice.name === 'Discography' && <DiskHits />}
                            {indice.name === 'Articles' && <ArticleHits />}
                            {indice.name === 'Interviews' && <InterviewHits />}
                            {indice.name === 'Artists' && <ArtistHits />}
                            {indice.name === 'Users' && <UserHits />}
                            {/* {indice.name !== 'Songs' && <Hits />} */}
                        </section>
                    </IndexResults>
                </Index>
            ))}
        </div>
    )
}

export default connectStateResults(SearchResults)
