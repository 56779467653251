import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Components
import Search from 'components/UIElements/Search'
// Styles
import * as styles from './SearchBar.module.css'

type IndexProps = {
    name: string
    title: string
}

const SearchBar: React.FC = ({}) => {
    const [showSearchForm, setShowSearchForm] = useState<boolean>(
        typeof window !== 'undefined' && !!location.search
    )

    const openSearchForm = () => setShowSearchForm(true)
    const closeSearchForm = () => setShowSearchForm(false)

    const searchIndices: IndexProps[] = [
        { name: `Songs`, title: `Canciones` },
        { name: `Discography`, title: `Discografía` },
        { name: `Articles`, title: `Articulos` },
        { name: `Interviews`, title: `Entrevistas` },
        { name: `Artists`, title: `Artistas` },
        { name: `Users`, title: `Usuarios` },
    ]

    return (
        <div className={styles.search}>
            <div className={styles.search_bar} onClick={openSearchForm}>
                <FontAwesomeIcon
                    className={styles.search_icon}
                    icon={['fas', 'search']}
                />
                <span className={styles.search_text}>Buscar</span>
            </div>
            <Search
                show={showSearchForm}
                indices={searchIndices}
                onClose={closeSearchForm}
            />
        </div>
    )
}

export default SearchBar
