import React from 'react'
import { createPortal } from 'react-dom'
import { Link } from 'gatsby'
// Components
import Backdrop from 'components/UIElements/Backdrop'
// Styles
import * as styles from './Menu.module.css'
import Brand from 'components/UIElements/Brand'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type MenuProps = {
    mobile: boolean | undefined
    show: boolean
    closeMenu: () => void
}

type MenuButtonsPros = {
    name: string
    to: string
    partiallyActive: boolean
}[]

const menuButtons: MenuButtonsPros = [
    { name: 'Home', to: '/', partiallyActive: false },
    { name: 'Noticias', to: '/articles', partiallyActive: true },
    { name: 'Discografía', to: '/discography', partiallyActive: true },
    { name: 'Entrevistas', to: '/interviews', partiallyActive: true },
    { name: 'Canciones', to: '/songs', partiallyActive: true },
]

const Menu: React.FC<MenuProps> = ({ mobile, show, closeMenu }) => {
    if (!mobile)
        return (
            <ul className={styles.menu}>
                {menuButtons.map(({ name, to, partiallyActive }) => (
                    <li key={to}>
                        <Link
                            to={to}
                            partiallyActive={partiallyActive}
                            activeClassName={styles.active}
                        >
                            {name}
                        </Link>
                    </li>
                ))}
            </ul>
        )

    if (!show) return <></>

    const menuMobileElement = (
        <aside className={styles.menu_mobile}>
            <Backdrop show onClose={closeMenu} />
            <header className={styles.menu_mobile_header}>
                <Brand />
            </header>
            <ul className={styles.menu_mobile_list}>
                {menuButtons.map(({ name, to, partiallyActive }) => (
                    <li key={to}>
                        <Link
                            to={to}
                            partiallyActive={partiallyActive}
                            activeClassName={styles.active}
                        >
                            {name}
                        </Link>
                    </li>
                ))}
            </ul>
            <footer className={styles.menu_mobile_footer}>
                <section className={styles.menu_mobile_footer_social}>
                    <a href="#">
                        <FontAwesomeIcon
                            className={styles.menu_mobile_footer_icon}
                            icon={['fab', 'twitter']}
                        />
                    </a>
                    <a href="#">
                        <FontAwesomeIcon
                            className={styles.menu_mobile_footer_icon}
                            icon={['fab', 'instagram']}
                        />
                    </a>
                    <a href="#">
                        <FontAwesomeIcon
                            className={styles.menu_mobile_footer_icon}
                            icon={['fab', 'facebook']}
                        />
                    </a>
                </section>
                <span className={styles.menu_mobile_copy}>
                    KenshiYonezu.net &copy; {new Date().getFullYear()}
                </span>
            </footer>
        </aside>
    )

    const portalTo =
        typeof document !== `undefined`
            ? document.getElementById('menu-hook')
            : null

    if (show && portalTo) {
        return createPortal(menuMobileElement, portalTo)
    }
}

export default Menu
