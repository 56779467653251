import React from 'react'

// Styles
import * as styles from './Main.module.css'

const Main: React.FC = ({ children }) => {
    return <main className={styles.main}>{children}</main>
}

export default Main
